<template>
	<v-card color="#D7D7D7">
		<v-card-text class="pa-0">
			<div class="px-4 py-2">
				<v-row no-gutters>
					<v-col cols="6" md="6" class="font-weight-bold">
						<span>Order ID: </span>
						<span>{{ item.id }}</span>
					</v-col>
					<v-col cols="6" md="6" class="font-weight-bold">
						<span>DK Code: </span>
						<span>{{ item.sd_id }}</span>
					</v-col>
					<v-col class="font-weight-bold">
						<span>Status: </span>
						<span>{{ item.status ? item.status.name : 'Processing' }}</span>
					</v-col>
				</v-row>
				<div>
					<span class="caption">{{ $dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
				</div>
			</div>
			<div>
				<v-btn
					block small
					depressed
					color="secondary"
					class="text-capitalize primary--text rounded-t-0"
					@click="$emit('viewOrder', item.id)"
				>
					View Order Summary
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props:{
		item:{
			type: Object,
			default: ()=>{
				return {}
			}
		}
	},
	data(){
		return {
		}
	},
	created(){
	},
	methods:{
	}
}
</script>
