<template>
	<v-container>
		<div>
			<div class="headline mb-1">ORDER HISTORY</div>
			<div class="pa-2 py-2">
				<div class="caption">YOUR ORDER(S)</div>
				<div class="x-caption">Displaying {{ resourceDataSet_.length }} of {{ totalData_ }}</div>
			</div>
		</div>
		<v-row
			ref="scrollField"
			v-scroll.self="onScroll"
			no-gutters
			class="overflow-auto pa-3"
			style="height: calc(100vh - 260px);"
		>
			<v-col cols="12" style="height: calc(100% + 1px);">
				<v-row>
					<!-- data list -->
					<template v-for="order in resourceDataSet_">
						<v-col
							:key="order.id"
							cols="12"
							sm="6" md="4" lg="3"
						>
							<OrderHistoryCard
								:item="order"

								@viewOrder="viewOrder"
							></OrderHistoryCard>
						</v-col>
					</template>
					<!-- state -->
					<template v-if="model_loading_">
						<v-row class="pa-6 col-12" no-gutters justify="center">
							<v-progress-circular indeterminate></v-progress-circular>
						</v-row>
					</template>
					<template v-else-if="resourceDataSet_.length == 0">
						<v-row class="pa-6 col-12" no-gutters justify="center">
							<span class="title font-italic">No Order History Found</span>
						</v-row>
					</template>
					<template v-else-if="resourceDataSet_.length > 0">
						<v-row class="pa-6 pb-16 col-12" no-gutters justify="center">
							<span class="body-2 font-italic">
								<span v-if="!hasMorePage && current_page > 0">~ End of Data ~</span>
								<span v-else>~ Scroll Up To Load More ~</span>
							</span>
						</v-row>
					</template>
				</v-row>
			</v-col>
		</v-row>
		<v-row justify="end">
			<v-col cols="12" md="4">
				<v-btn
					block
					depressed
					color="primary"
					class="text-capitalize secondary--text"
					to="/new-order"
				>
					Create Leather Interior Seats
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import OrderHistoryCard from "./OrderHistoryCard"
export default {
	components:{
		OrderHistoryCard,
	},
	mixins: [
		resourceMixin,
	],
	data(){
		return {
			hasMorePage: false,
			scrollLock: false,
			current_page: 0,
			bottomSpaceHeight: 1,
		}
	},
	created(){
		this.initialize_()
	},
	methods:{
		getResourceData_(page = 1){
			let payload = {
				page: page,
				itemsPerPage: 5,
			}
			this.model_loading_ = true
			this.$api.getOrderList(payload).then((res)=>{
				let { data, total, next_page_url, current_page } = res.data.result
				this.totalData_ = total
				this.hasMorePage = !!next_page_url
				this.current_page = current_page
				this.resourceDataSet_ = [ ...this.resourceDataSet_, ...data ]
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
		onScroll(e){
			let scrollField = this.$refs.scrollField;
			let scrollFieldHeight = scrollField.clientHeight
			let totalDataHeight = scrollField.scrollHeight
			let scrollY = e.target.scrollTop
			let isBottomOfPage = Math.ceil(scrollFieldHeight + scrollY) >= totalDataHeight
			if(this.hasMorePage && (isBottomOfPage) && this.model_loading_ == false){
				this.model_loading_ = true
				setTimeout(() => {
					this.getResourceData_(this.current_page+1)
				}, 1000 * 1);
			}
		},
		viewOrder(id)
		{
			this.$router.push({path: "/order-histories/info", query: { id: id }})
		}
	}
}
</script>
